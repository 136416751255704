@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
}

:root {
    --primary: #1D7ABF;
    --blue: #0092FF;
    --red: #FF4646;
    --green: #00FF00;
    --dark-black-text: #434343;
    --light-profile-img-contentblack-text: #696969;
    --blue-text: #0092FF;
    --blue-title-text: #1D7ABF;
    --app-width: 1144px;
}

*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #888;
    box-sizing: border-box;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary);
}

html {
    height: -webkit-fill-available;
}

body {
    background: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: -webkit-fill-available;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rubik', sans-serif;
    cursor: default;
    color: var(--blue-title-text);
}

p,
a,
li {
    font-family: 'Roboto', sans-serif;
    cursor: default;
    color: #333;
    text-decoration: none;
}
a{
    cursor: pointer;
}

h1{
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 800;
}
h2{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 800;
}
h3{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 800;
}
p{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
section {
    max-width: var(--app-width);
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
    box-sizing: border-box;
}

section h1,
section h2 {
    font: normal normal normal 38px/40px Roboto;
    margin-bottom: 40px;
}

section h6 {
    font: normal normal normal 24px/34px Roboto;
    margin-bottom: 20px;
}
article{
    line-height: 32px;
}
article p{
    padding-bottom: 14px;
}
/* Header */
header {
    width: 100%;
    height: 56px;
    background-color: var(--primary);
    box-shadow: 0 2px 1px -1px rgb(0    0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    position: sticky;
    top: 0;
    z-index: 500;
}

.header-container {
    max-width: var(--app-width);
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header-container .logo {
    width: 170px;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;

}

.header-container .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header-container .MuiListItemText-root,
.header-container .MuiSvgIcon-root {
    color: #ABCDE7 !important;
    transition: all 0.2s ease-in-out;
}

.header-container .MuiListItemButton-root {
    transition: all 0.2s ease-in-out;
}

.header-container .MuiListItemButton-root.Mui-selected {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.header-container .MuiListItemButton-root.Mui-selected .MuiListItemText-root,
.header-container .MuiListItemButton-root.Mui-selected .MuiSvgIcon-root {
    color: #F9F9F9 !important;
}

.header-container .MuiListItemIcon-root {
    min-width: auto !important;
    padding-right: 8px;
}

/* End of header */

/* Footer */
footer {
    width: 100%;
    background-color: #FAFAFA;
    box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.161);
    box-sizing: border-box;
}

.footer-content {
    max-width: var(--app-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 40px;
}

.footer-content h3 {
    color: var(--dark-black-text);
    font: normal normal normal 24px/24px Roboto;
    margin-bottom: 16px;
}

.footer-content p {
    color: var(--light-black-text);
    font: normal normal normal 16px/16px Roboto;
    margin-bottom: 16px;
}

.footer-content p.active {
    color: var(--blue-text);
}

.footer-content .address p {
    font-size: 12px;
    margin-bottom: 8px;
}

.footer-right {
    text-align: center;
    background-color: #ffffff;
    padding: 16px 0;
}

.footer-right p:first-child {
    padding-bottom: 8px;
}

.footer-right p {
    color: #BFBFBF;
}

/* End of footer */

/* Home */
.slider {
    width: 100%;
    height: 450px;
}

.slider img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-content {
    width: 70%;
}

/* End of home */

.special-button {
    text-transform: none !important;
    border-radius: 24px !important;
}

/* Search Page */
.search-section {
    display: flex;
    align-items: flex-start;
}

.search-filter {
    width: 400px;
    min-height: 200px;
    border-radius: 12px;
    position: sticky;
    top: 100px;
}

.search-items-content {
    width: calc(100% - 400px);
    min-height: 200px;
    margin-left: 40px;
}

.search-items-content-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-items-content-header button {
    text-transform: none !important;
    margin-left: 16px;
}

.search-items-content-header-main h1 {
    margin-bottom: 8px;
}

.search-items-content-header-main p {
    font: normal normal normal 16px/18px Roboto;
    color: #888888;
}

.search-items-content-header-action {
    display: flex;
}

.search-items-content-header-action .sorting-control .MuiOutlinedInput-root {
    background-color: var(--blue) !important;
    width: 120px;
    border-radius: 24px !important;
    height: 40px !important;
}

.search-items-content-header-action .sorting-control .MuiInputLabel-root,
.search-items-content-header-action .sorting-control .MuiSvgIcon-root {
    color: #fff;
}

.search-items-content-header-action .sorting-control .MuiInputLabel-root {
    top: -8px !important;

}

.search-items-content-header-action .sorting-control .MuiInputLabel-root.Mui-focused {
    color: #fff;
}

.search-items-content-header-action .sorting-control .MuiSelect-select {
    color: #fff;
}

/* End of Search Page */

/* Profile */
.profile-section {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;

}

.profile-card {
    background-color: #F2F2F2;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.161);
    border-radius: 12px;
    position: relative;
    padding: 24px;
    box-sizing: border-box;
}

.profile-img {
    width: 130px;
    height: 130px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: -12%;
    transform: translateX(-50%);
    border-radius: 50%;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.161);
}

.profile-img-content {
    position: relative;
}

.profile-field .MuiFormControl-root:nth-child(odd) {
    width: calc(50% - 12px) !important;
    margin-right: 24px !important;
    margin-bottom: 24px !important;
}

.profile-field .MuiFormControl-root:nth-child(even) {
    width: calc(50% - 12px) !important;
}

.profile-img-content button,
.profile-img-content label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff !important;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.161) !important;
}

.profile-img-content .profile-image {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-img-content .profile-image img {
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 50%;
}

.profile-name {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 24px;
}

.profile-name p {
    color: var(--blue-text);
    font: normal normal normal 25px/33px Roboto;
}

/* End of Profile */

/* Favorite seciotn */
.favorite-section {
    display: flex;
    flex-direction: row;
}

.favorite-container {
    width: 75%;
}

.favorite-announce {
    width: 25%;
}

/* End of Favorite */


/* Messages */
.message-content {
    display: flex;
    height: 544px;

}

.message-content .message-content-users {
    width: 50%;
    height: 100%;
    overflow-y: auto;
    padding: 0 8px;
    box-sizing: border-box;
}

.message-content .message-content-chat {
    width: 50%;
    padding-left: 40px;
    box-sizing: border-box;
    padding: 0 8px;
    box-sizing: border-box;
}


/* View Item container */
.view-item-container {
    display: flex;
    justify-content: space-between;
}

.view-item-info-content {
    width: calc(70% - 16px);
    border: 1px solid #BEBEBE;
    border-radius: 12px;
    padding: 24px !important;
    box-sizing: border-box;
}

.view-item-contact-content {
    width: 30%;
    border: 1px solid #BEBEBE;
    border-radius: 12px;
    padding: 24px !important;
    box-sizing: border-box;
}

/* add-item-container */
.add-item-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

/* End of messages */

/* register-container */
.register-container {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
}

.register-content {
    background-color: #F2F2F2;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.161);
    border-radius: 12px;
    position: relative;
    padding: 24px;
    box-sizing: border-box;
    padding-top: 64px;
}

.register-content .MuiOutlinedInput-root,
.profile-card .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 12px !important;
    height: 48px !important;
}

.register-content .MuiOutlinedInput-notchedOutline,
.profile-card .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.register-content .MuiInputLabel-root.Mui-focused,
.profile-card .MuiInputLabel-root.Mui-focused {
    top: 0 !important;
}

.adding-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    gap: 16px !important;
    margin-bottom: 16px;
}

.adding-container .MuiTextField-root,
.adding-container .MuiFormControl-root {
    width: calc(50% - 8px) !important;
}

.adding-container .description-field.MuiTextField-root {
    width: 100% !important;
}

.adding-container .images-container {
    width: 100%;
    min-height: 100px;
    border: 1px solid #BEBEBE;
    border-radius: 4px;
    cursor: pointer;
}

.adding-container .images-container .images-container-none {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.adding-container .images-container .images-container-none p {
    color: #B7B7B7;
    margin: 16px;
    font: normal normal normal 24px/20px Roboto;
}

.adding-container .images-container .images-container-none svg {
    color: #B7B7B7;
    width: 84px;
    height: 84px;
}

.adding-container .images-container .images-container-none-icon {
    display: flex;
    align-items: center;
}

.images-container-imgs-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
    gap: 16px;
}

.adding-container .images-container-imgs {
    width: 100px;
    height: 100px;
    border: 1px solid #BEBEBE;
    box-sizing: border-box;
    display: flex;
    border-radius: 4px;
    position: relative;
}

.adding-container .images-container-imgs:hover {
    border: 1px solid #969696;

}

.adding-container .images-container-imgs img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

}

.adding-container .images-container-imgs .delete-image {
    position: absolute;
    top: -12px;
    left: -12px;
    display: none;
    transition: all 0.2s ease-in-out;
}

.adding-container .images-container-imgs:hover .delete-image {
    display: block;
}

.adding-container .images-container-imgs .delete-image button {
    background-color: var(--red) !important;
}

.adding-container .images-container-imgs .delete-image button svg {
    color: #fff !important;
}

.adding-container .images-container-add-more-img{

}
.adding-container .images-container-add-more-img button{
    background-color: #e2e2e2;
}

.adding-container .primary-image-container{  
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.images-container-primary-image-content{
    width: 400px;
    height: 100%;
    border: 1px solid #BEBEBE;
    border-radius: 4px;
    cursor: pointer;

}
.primary-image-container-none{
    height: 100%;
    position: relative;
}
.primary-image-container-none p{
    color: #B7B7B7;
    margin: 16px;
    font: normal normal normal 24px/20px Roboto;
}
.primary-image-container-none-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.images-container-primary-image-content .primary-image-container-none-icon svg {
    color: #B7B7B7;
    width: 84px;
    height: 84px;
}


.primary-images-container-imgs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    flex-wrap: wrap;
    position: relative;
}



.primary-images-container-imgs img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.primary-image-edit-view{
    display: none;
    
}
.primary-image-edit-view svg{
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 0, 0.4);
    border-radius: 50%;
    fill: #ffffff;
}
.primary-images-container-imgs:hover .primary-image-edit-view{
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* .images-container-primary-image-content .primary-image-container-none-icon {
    display: flex;
    align-items: center;
} */


/* End of Register */

.category-selection-boxes {
    display: flex;
}

.MuiButton-root {
    border-radius: 26px !important;
    text-transform: none !important;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.no-shadow {
    box-shadow: none !important;
}

.MuiPaper-root {
    background-color: #F2F2F2 !important;
}

.MuiAlert-filledError {
    background-color: #d32f2f !important;
    position: fixed;
    right: 24px;
    bottom: 24px;
    animation-name: alertView;
    animation-duration: 0.2s;
    animation-iteration-count: alternate ;
}

.MuiAlert-filledSuccess{
    background-color: var(--primary) !important;
    position: fixed;
    right: 24px;
    bottom: 24px;
    animation-name: alertView;
    animation-duration: 0.2s;
    animation-iteration-count: alternate ;
}

@keyframes alertView {
    from {
        right: -100%;
    }

    to {
        right: 24px;
    }
}

.saved-link-container {
    border: 1px solid #BEBEBE;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 24px;
    min-height: 100px;
    margin-bottom: 16px;
}

@media only screen and (max-width: 1166px) {
    .home-content {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }

    .header-container .logo {
        margin-left: 16px;
    }

    section {
        padding: 24px 16px;
        box-sizing: border-box;
    }

    .favorite-section {
        flex-direction: column;
    }

    .favorite-container {
        width: 100%;
    }

    .favorite-announce {
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .home-content {
        padding: 0 8px;
    }

    .header-container .logo {
        margin-left: 8px;
    }

    .footer-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-content h3 {
        text-align: center;
    }

    .footer-content p {
        text-align: center;
    }

    section h1,
    section h2 {
        font: normal normal normal 28px Roboto;
        margin-bottom: 24px;
    }

    .view-item-container {
        flex-direction: column;
    }

    .view-item-info-content,
    .view-item-contact-content {
        box-sizing: border-box;
        width: 100%;
        padding: 8px !important;
    }

    .view-item-info-content {
        margin-bottom: 8px;

    }

    section {
        padding: 24px 8px;
        box-sizing: border-box;
    }

    .search-section {
        flex-direction: column;
    }

    .search-filter {
        position: static;
        width: 100%;
    }

    .search-items-content {
        width: 100%;
        margin-left: 0;
        margin-top: 16px;
    }

    .search-items-content-header {
        align-items: flex-start;
    }

    .search-items-content-header button {
        font-size: 8px !important;
        padding: 4px !important;
    }

    .profile-section {
        margin-top: 64px;
    }

    .profile-card {
        padding: 8px !important;
    }

    .profile-field .MuiFormControl-root:nth-child(odd) {
        width: 100% !important;
        margin-bottom: 16px !important;
        margin-right: 0 !important;
    }

    .profile-field .MuiFormControl-root:nth-child(even) {
        width: 100% !important;
        margin-bottom: 16px !important;

    }

    .adding-container .MuiTextField-root,
    .adding-container .MuiFormControl-root {
        width: 100% !important;
    }
}