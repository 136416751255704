.search-box {
    background-color: #F2F2F2;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 40px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.161);
}

.search-box .MuiBox-root {
    border-bottom: none !important;
}

.search-box .MuiTabs-flexContainer button {
    transition: all 0.2s ease-in-out !important;
}

.search-box .MuiTabs-flexContainer button.Mui-selected {
    background-color: #D8E8F4 !important;
}

.search-box .MuiOutlinedInput-root {
    background-color: #FFFFFF;
    border-radius: 12px !important;
    height: 48px !important;
}

.search-box .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.search-box .MuiInputLabel-root {
    top: -4px !important;
}

.search-box .MuiInputLabel-root.Mui-focused {
    top: 0 !important;
}

.item-card {
    width: 100%;
    height: 288px;
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    background-color: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 24px;
    position: relative;
}
.item-card-img{
    width: 176px;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    margin-right: 16px;
    position: relative;
}
.item-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.item-card-img .img-count{
    position: absolute;
    bottom: 16px;
    left: 16px;
    background-color: rgba(0, 145, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 8px;
    box-sizing: border-box;
    border-radius: 8px;
}
.item-card-img .img-count p{
    margin-left: 8px;
}
.item-card-img .img-count p,
.item-card-img .img-count svg{
    color: #fff;
}
.item-card-content{
    width: calc(100% - 176px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item-card-content-head{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.item-card-content-head h2{
    margin-bottom: 0;
    cursor: pointer;
}
.item-card-main-catergory{
    display: flex;
}
.item-card-main-catergory p,
.item-card-main-catergory h3{
    border-right: 1px solid var(--blue-text);
    margin-right: 8px;
    padding-right: 8px;
    color: var(--blue-text);
}
.item-card-main-catergory p:last-child,
.item-card-main-catergory h3:last-child{
    border-right: none;
}

.item-card-content-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1;
}

.item-card-content-body p{
    font: normal normal normal 15px/20px Roboto;
    color: #888888;
    letter-spacing: 0px;
}
.item-card-content-footer{
    width: 100%;
}
.item-card-secondary-catergory{
    display: flex;
    margin-bottom: 16px;
} 
.item-card-secondary-catergory p{
    color: var(--blue-text);
    border: 1px solid #d4d4d4;
    border-radius: 18px;
    padding: 8px 16px;
    font-size: 11px;
    margin-right: 8px;
    font-weight: bold;
}
.item-card-price p{
    font-size: 24px;
    color: var(--blue-text);
}

.treating-status{
    position: absolute;
    right: 0;
    top: 64px;
    padding: 8px 16px;
    box-sizing: border-box;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}
.treating-status p{
    color: #ffffff;
}
.treating-status-blue{
    background-color: var(--primary);
}
.treating-status-green{
    background-color: var(--green);

}
.treating-status-red{
    background-color: var(--red);

}

/* User Chat */
.user-chat-container{
    width: 100%;
    height: 120px;
    background-color: #F2F2F2;
    border-radius: 12px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.161);
    margin-bottom: 16px;
}
.user-chat-container.active{
    background-color: var(--blue) !important;
}
.user-chat-content{
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.user-chat-content-header{
    display: flex;
}
.user-chat-content-header-img{
    width: 50px;
    height: 50px;
    background-color: var(--primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-chat-content-header-img svg{
    color: #fff !important;
    width: 36px !important;
    height: 36px !important;
}

.user-chat-content-header-title{
    flex: 1;
    margin-left: 16px;
}
.user-chat-content-header-title h3{
    font: normal normal normal 32px/32px Roboto;
}
.user-chat-container.active .user-chat-content-header-title h3{
    color: #fff;
}
.user-chat-content p{
    font: normal normal normal 16px/24px Roboto;
    color: #B5B5B5;
}
.user-chat-container.active .user-chat-content p{
    color: #fff;
}

/* Chat card */
.chat-card{
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.chat-card-header{
    border: 1px solid #BEBEBE;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
}

.chat-card-header-img svg{
    width: 70px !important;
    height: 70px !important;
    color: #ACACAC;
}
.chat-card-header-title{
    flex: 1;
   
}
.chat-card-header-title p{
    font: normal normal normal 16px/16px Lato;
    font-weight: bold;
    color: var(--blue);
}
.chat-card-header-title p:last-child{
    font: normal normal normal 24px/24px Lato;
}

.chat-card-body{
    flex: 1;
    overflow-y: auto;
    padding: 16px 8px;
}

.chat-card-body-receiver-container{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}
.chat-card-body-receiver-content{
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: #0092FF;
    border-radius: 12px;
    max-width: 75%;
    box-shadow: 0px 3px 6px #00000029;
}
.chat-card-body-receiver-content p{
    color: #ffffff;
    line-height: 24px;
}

.chat-card-body-sender-container{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;    
}
.chat-card-body-sender-content{
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: #FAFAFA;
    border-radius: 12px;
    max-width: 75%;
    box-shadow: 0px 3px 6px #00000029;
}
.chat-card-body-sender-content p{
    color: #0092FF;
    line-height: 24px;
}
.chat-card-body-receiver-container:last-child,
.chat-card-body-sender-container:last-child{
    margin-bottom: 0;
}



.chat-card-footer{
    display: flex;
    align-items: center;
}
.chat-card-footer .MuiOutlinedInput-root {
    background-color: #f4f4f4 !important;
    border-radius: 12px !important;
}

.chat-card-footer .MuiOutlinedInput-notchedOutline {
    border: 1px solid #f4f4f4 !important;
}

.chat-card-footer button{
    background-color: var(--blue) !important;
    margin-left: 16px;
}
.chat-card-footer button svg{
    color: #fff !important;
}

/* View Item card */

.view-item-card{
    width: 100%;
    height: 100%;
}
.view-item-card-main{
    display: flex;
    justify-content: space-between;
    height: 380px;
}
.view-item-card-img{
    width: calc(36% - 16px);
    height: 380px;
    position: relative;
    margin-right: 16px;
}
.view-item-card-img img{
    width: 100%;
    height: 380px;

    object-fit: cover;
    border-radius: 12px;
}
.view-item-card-img .img-count{
    position: absolute;
    bottom: 16px;
    left: 16px;
    background-color: rgba(0, 145, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 8px;
    box-sizing: border-box;
    border-radius: 8px;
}
.view-item-card-img .img-expand{
    position: absolute;
    bottom: 16px;
    right: 16px;
    background-color: rgba(0, 145, 255, 0.8);
    border-radius: 50%;
}
.view-item-card-img .img-count p{
    margin-left: 8px;
}
.view-item-card-img .img-count p,
.view-item-card-img .img-count svg,
.view-item-card-img .img-expand svg{
    color: #fff;
}


.view-item-card-img-expand-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.view-item-card-img-expand,
.view-item-card-content{
    width: 64%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.view-item-card-content-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.view-item-card-content-header-title{
    flex: 1;
}
.view-item-card-content-header-title h1{
    font: normal normal normal 44px/58px Roboto;
    margin-bottom: 8px;
}
.view-item-card-content-header-main-cat{
    display: flex;
}

.view-item-card-content-header-main-cat p{
    border-right: 1px solid #0092FF;
    margin-right: 8px;
    padding-right: 8px;
    color: #0092FF;
    font: normal normal normal 24px/24px Roboto;
}
.view-item-card-content-header-main-cat p:last-child{
    border: none
}

.view-item-card-content-body{
    display: flex;
    flex-wrap: wrap;
}

.view-item-card-content-body p{
    color: var(--blue-text);
    border: 1px solid #d4d4d4;
    border-radius: 18px;
    padding: 8px 16px;
    font-size: 11px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}

.view-item-card-content-footer p{
    color: var(--blue-text);
    font: normal normal normal 50px/50px Roboto;
}

.view-item-card-description{
    margin-top: 24px;
}
.view-item-card-description p{
    font: normal normal normal 16px/24px Brother 1816;
    color: #888888;
}

/* View item contact card */

.view-item-contact-card{
    display: flex;
    flex-direction: column;
}
.view-item-contact-card h3{
    text-align: center;
    color: var(--blue-text);
    font: normal normal normal 28px/37px Roboto;
    margin-bottom: 24px;
}

.view-item-contact-username,
.view-item-contact-user-address{
    margin-bottom: 16px;
}
.view-item-contact-username-content,
.view-item-contact-user-address-content{
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
}
.view-item-contact-username-content-icon,
.view-item-contact-user-address-content-icon {
    margin-right: 8px;
}
.view-item-contact-username-content-name p:first-child,
.view-item-contact-user-address-content-name p:first-child{
    font: normal normal normal 21px/28px Roboto;
    color: #8D8D8D;
}
.view-item-contact-username-content-name p:last-child,
.view-item-contact-user-address-content-name p:last-child{
    font: normal normal normal 15px/20px Roboto;
    color: #B5B5B5;
}

.view-item-contact-user-map{
    width: 100%;
    height: 140px;
    background-color: #B5B5B5;
    margin-bottom: 16px;
}

.view-item-contact-user-phone,
.view-item-contact-user-mail{
    margin-bottom: 16px;
    padding: 0 40px;
    box-sizing: border-box;
}

/* category-box */
.category-box{
    width: 120px;
    height: 120px;
    border: 1px solid #BEBEBE;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.161);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 16px;
}
.category-box p{
    font-size: 24px;
    cursor: pointer;
}

.expanded-images{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
}
.close-expanded-images{
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 5001;
}
.close-expanded-images button{
    background-color: var(--primary);
    color: #fff;
}
.expanded-images-content{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4900;
}
.expanded-images-content-cover{
    margin-top: 2.5%;
    width: 100%;
    height: 100vh;
}

.expanded-images-content img{
    object-fit: contain !important;
    width: 100%;
    height: 90%;
}


.expanded-images .overlay{
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4800;
}

@media only screen and (max-width: 1166px){

}

@media only screen and (max-width: 600px){
    .item-card{
        height: 180px;
        padding: 8px;
    }
    .item-card-img{
        width: 25%;
        margin-right: 8px;
    }
    .item-card-content{
        width: 75%;
    }
    .item-card-content-head{
        align-items: center;
        margin-bottom: 0;
    }
    .item-card-content-head h2{
        font: normal normal normal 28px/28px Roboto;
        margin-bottom: 0;
    }
    .item-card-main-catergory p{
        font: normal normal normal 12px Roboto;
    }
    .item-card-content-body p{
        font: normal normal normal 11px Roboto;
    }
    .item-card-content-description{
        font: normal normal normal 12px Roboto;
    }
    .item-card-secondary-catergory{
        margin-bottom: 8px;
    } 
    .item-card-secondary-catergory p{
        padding: 4px 8px;
        font-size: 9px;
    }
    .item-card-price p{
        font-size: 18px;
    }
    .view-item-card-main{
        flex-direction: column;
   
        height: auto;
    }
    .view-item-card-img{
        width: 100%;
        margin-bottom: 8px;
        margin-right: 0;
    }
    .view-item-card-content{
        width: 100%;
    }

    .view-item-card-content-body{
        flex-direction: row;
        width: 100%;
    }
    .category-box{
        width: 100px;
        height: 100px;
    }
    .category-box p{
        font-size: 18px;
    }
}